@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body, button, textarea {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea,
input {
  padding: 12px 16px;
  border: 1px solid #D6E4E7;
  border-radius: 2px;
  outline: none;
  caret-color: #10ADD0;
}

textarea::placeholder,
input::placeholder {
  color: #BBBBBB;
}

textarea:hover,
textarea:focus,
input:hover,
input:focus {
  border-color: #10ADD0;
}

textarea.is-error,
input.is-error {
  border-color: #E1414F;
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
  input {
    max-height: 43px;
    font-size: 16px;
  }
}

input[type="checkbox"] {
  position: relative;
  height: 18px;
  width: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: text-top;
  border: none;
  padding: 0;
  z-index: 0;
}

input[type="checkbox"]:hover:before {
  border-color: #10ADD0;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #DADADA;
  border-radius: 2px;
  box-sizing: border-box;
}

input[type="checkbox"]:checked {
  background-color: #10ADD0;
}

input[type="checkbox"]:disabled {
  background-color: #DADADA;
}

input[type="checkbox"]:indeterminate:before,
input[type="checkbox"]:checked:before {
  height: 6px;
  width: 11px;
  transform: rotate(-50deg);
  border-top-style: none;
  border-right-style: none;
  border-color: white;
  margin-left: 3.5px;
  margin-top: 5px;
  border-radius: 0;
}

input[type="checkbox"]:indeterminate {
  border: 2px solid #10ADD0;
}

input[type="checkbox"]:indeterminate:hover {
  background-color: #10ADD0;
}

input[type="checkbox"]:indeterminate:hover:before {
  border-color: white;
}

input[type="checkbox"]:indeterminate:before {
  width: 10px;
  border-color: #10ADD0;
  margin-top: 3px;
  margin-left: 2px;
}

[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 13px;
  width: 13px;
  padding: 0;
  border: none;
}

[type="radio"]:checked,
[type="radio"]:not(:checked)
{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
[type="radio"]:checked:before,
[type="radio"]:not(:checked):before {
  content: '';
  position: absolute;
  /* left: -1px; */
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  box-sizing: border-box;
}

[type="radio"]:hover:before,
[type="radio"]:checked:before {
  border-color: #10ADD0;
}

[type="radio"]:checked:after,
[type="radio"]:not(:checked):after {
  content: '';
  width: 7px;
  height: 7px;
  background: #10ADD0;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked):after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

