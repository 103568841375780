.locations-page {
    min-height: 100vh;
    padding-top: 24px;
}

.ant-tooltip {
    display: none;
}

.locations-page__mobile-filters {
    display: none;
}

.locations-page .filters__row-title {
    color: black;
    margin-bottom: 24px;
}

.locations-page .checkbox-filter label {
    color: black;
}

.locations-page__content {
    margin-top: 32px;
}

.locations-page__content .filters-column,
.locations-page__content .locations-page__results {
    display: inline-block;
}

.locations-page .filters .advanced-filters-action__btn {
    color: black;
}

.locations-page__content .form-group__hint svg path,
.locations-page .filters .advanced-filters-action__icon * {
    fill: black;
}

.locations-page__no-data,
.locations-page__content .locations-page__results {
    vertical-align: top;
    width: calc(100% - 224px);
    margin-left: 24px;
    margin-bottom: 32px;
}

.locations-page__results table {
    width: 100%;
    border-spacing: 0;
}

.locations-page__results table  .ant-empty {
    display: none;
}

.locations-page__results thead th {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #828282;
    text-align: left;
    padding-left: 16px;
    cursor: pointer;
}

/*.locations-page__results thead th:nth-last-child(2) .ant-table-column-sorters,*/
/*.locations-page__results thead th:nth-last-child(3) .ant-table-column-sorters {*/
/*    white-space: normal;*/
/*}*/

.locations-page__results tbody:before {
    content: '';
    display:block;
    height: 8px;
}

.locations-page__results tbody tr td {
    padding: 16px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    vertical-align: top;
}

.locations-page__results tbody tr td:first-child {
    width: 200px;
}

.locations-page__results.is-visible-state tbody tr td:nth-child(2) {
    display: none;
}

.ant-table-column-sorter {
    display: none;
}

.locations-page__results tbody tr td .cell__label {
    display: none;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
    width: 80%;
}

.locations-page__results tbody tr td .cell__value {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.locations-page__results tbody tr:nth-child(2n) {
    background-color: #F1F6F8;
}

.locations-page__results tbody tr:hover {
    background-color: #D6E4E7;
    cursor: pointer;
}

.locations-page__results__locations-container {
    position: relative;
}

.locations-page__results__location {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
}

.locations-page__results__university {
    vertical-align: text-top;
    height: 14px;
}

.locations-page__results__university-hint-container {
    height: 16px;
    margin-top: 4px;
}

.locations-page__results__university-hint {
    width: 100px;
    text-align: center;
    min-width: auto;
    font-size: 12px;
}

.locations-page__results__state {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #444444;
}

.locations-page__results .ant-table-pagination {
    list-style: none;
    margin-top: 16px;
    text-align: right;
}
.locations-page__results .ant-table-pagination li.ant-pagination-prev,
.locations-page__results .ant-table-pagination li.ant-pagination-item-active,
.locations-page__results .ant-table-pagination li.ant-pagination-next {
    display: inline-block;
}

.locations-page__results .ant-table-pagination li.ant-pagination-item-active {
    color: #10ADD0;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 600;
}

.locations-page__results .ant-table-pagination li button {
    background: none;
    border-radius: 2px;
    padding: 4px;
    outline: none;
    text-transform: uppercase;
    color: #10ADD0;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #10ADD0;
}

.locations-page__results .ant-table-pagination li button:hover {
    background-color: #10ADD0;
    color: white;
}

.locations-page__results .ant-table-pagination li button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.locations-page__results .ant-table-pagination li {
    display: none;
}

.locations-page__no-data {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.ant-table-column-sorters {
    white-space: nowrap;
}

.table-header {
    display: inline-block;
}

.table-header span {
    vertical-align: text-bottom;
}

.table-header.is-ascend-sort .table-header__icon,
.table-header.is-descend-sort .table-header__icon {
    opacity: 1;
}

.table-header.is-ascend-sort span,
.table-header.is-descend-sort span {
    color: #10ADD0;
}

.table-header.is-descend-sort .table-header__icon {
    transform: rotate(180deg);
}

.table-header__icon {
    display: inline-block;
    opacity: 0;
    margin-left: 8px;
}

.locations-page__results__filter:hover path,
.table-header__icon path {
    fill: #10ADD0;
}

.table-header__filter {
    position: relative;
}

.locations-page__results__filter {
    cursor: pointer;
}

.locations-page__results__list-columns {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 24px;
    list-style: none;
    width: 250px;
    animation: visible 0.3s ease-in-out;
    z-index: 1;
}

@keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.locations-page__results__list-columns__item {
    display: list-item;
    margin-bottom: 16px;
    line-height: 24px;
    width: 100%;
}

.locations-page__results__list-columns__item:last-child {
    margin-bottom: 0;
}

.locations-page__results__list-columns__item input {
    float: right;
}

.locations-page__content .advanced-filters-action {
    display: none;
}

@media (min-width: 900px) {
    .locations-page__content .advanced-filters-container {
        display: block;
    }
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .locations-page__content .advanced-filters-action {
        display: inline-block;
    }

    .locations-page__mobile-filters {
        display: block;
    }

    .locations-page__content .locations-page__content__advanced-filters {
        display: none;
    }

    .locations-page__content {
        margin-top: 24px;
    }

    .locations-page__content .filters-column {
        width: 100%;
    }

    .filters-column .filters__row:nth-child(8) {
        margin-bottom: 0;
    }

    .locations-page__content .advanced-filters-action__btn {
        color: black;
    }

    .locations-page__content .advanced-filters-action__icon path {
        fill: black;
    }

    .locations-page__content .locations-page__content__advanced-filters.is-show {
        display: block;
    }

    .locations-page__no-data,
    .locations-page__content .locations-page__results {
        width: calc(100% + 48px);
        margin-left: -24px;
    }

    .locations-page__results tbody,
    .locations-page__results thead {
        display: block;
    }

    .locations-page__results thead tr {
        display: inline-block;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }

    .locations-page__results thead th {
        margin-right: 32px;
        padding: 0;
        display: inline-block;
    }

    .locations-page__results tbody tr {
        display: block;
        width: 100%;
        padding: 12px 24px;
    }

    .locations-page__results tbody tr td {
        display: block;
        padding: 4px;
    }

    .locations-page__results thead th:nth-child(2),
    .locations-page__results thead th:nth-last-of-type(1),
    .locations-page__results tbody tr td:nth-last-of-type(1) {
        display: none;
    }

    .locations-page__results tbody tr td .cell__label {
        display: inline-block;
    }

    .locations-page__results tbody tr td .cell__value {
        width: 20%;
        text-align: right;
    }

    .locations-page__results .ant-table-pagination {
        margin-right: 24px;
    }
}
