@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
.get-in-touch-form {
    text-align: center;
}

.get-in-touch__label {
    display: block;
    color: #444444;
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
}

.get-in-touch__status {
    width: 100%;
    padding: 16px;
    text-align: center;
    -webkit-animation: visible 0.3s ease-in-out;
            animation: visible 0.3s ease-in-out;
}

@-webkit-keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.get-in-touch__status.is-success {
    color: #10ADD0;
}

.get-in-touch__status.is-error {
    color: #E1414F;
}

.get-in-touch-form__input {
    padding: 11px 16px;
    font-size: 16px;
    line-height: 24px;
    min-width: 360px;
    margin-right: 16px;
}

.get-in-touch-form__input::-webkit-input-placeholder {
    color: #BBBBBB;
}

.get-in-touch-form__input:-ms-input-placeholder {
    color: #BBBBBB;
}

.get-in-touch-form__input::-ms-input-placeholder {
    color: #BBBBBB;
}

.get-in-touch-form__input::placeholder {
    color: #BBBBBB;
}

.get-in-touch-form__input:hover,
.get-in-touch-form__input:focus {
    border-color: #10ADD0;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    position: relative;
}

.title:after {
    content: '';
    width: 20%;
    min-width: 58px;
    height: 4px;
    background: #10ADD0;
    border-radius: 4px;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
}

.service-card {
    width: 354px;
    border: 1px solid #F1F6F8;
    border-radius: 4px;
    text-decoration: none;
    vertical-align: top;
}

@media (min-width: 1900px) {
    .service-card {
        min-height: 170px;
    }
    .service-card.de,
    .service-card.ru
    {
        min-height: 160px;
    }
}

@media (max-width: 1900px) {
    .service-card {
        min-height: 210px;
    }
    .service-card.de,
    .service-card.ru
    {
        min-height: 180px;
    }
}

@media (max-width: 1680px) {
    .service-card.de
    {
        min-height: 220px;
    }
    .service-card.ru {
        min-height: 280px;
    }
}

@media (max-width: 1280px) {
    .service-card {
        min-height: 220px;
    }
    .service-card.de
    {
        min-height: 250px;
    }
    .service-card.ru {
        min-height: 330px;
    }
}

@media (max-width: 1100px) {
    .service-card {
        min-height: 270px;
    }
    .service-card.de,
    .service-card.ru {
        min-height: 370px;
    }
}

@media (max-width: 900px) {
    .service-card {
        min-height: 200px;
    }
    .service-card.de {
        min-height: 230px;
    }
    .service-card.ru {
        min-height: 230px;
    }
}

@media (max-width: 900px) and (orientation: landscape) {
    .service-card {
        min-height: 200px;
    }
    .service-card.de,
    .service-card.ru {
        min-height: 220px;
    }
}

.service-card img {
    height: 45%;
    width: 100%;
    border-bottom: 1px solid #F1F6F8;
}

.service-card__info-block {
    text-align: center;
    padding: 16px 25px;
    min-height: inherit;
    position: relative;
}

.service-card__info-block > div {
    display: table-cell;
}

.service-card__name {
    color: black;
    font-weight: 500;
}

.service-card__desc {
    color: #727272;
    line-height: 22px;
    display: block;
    margin: 8px 0;
    font-weight: normal;
}

.service-card__link {
    line-height: 22px;
    color: #10ADD0;
    font-weight: 500;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
}

.select {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

div.select:hover .select-label {
    color: #10ADD0;
}

div.select:hover .select-cursor {
    border-top-color: #10ADD0;
}

.select-label {
    font-size: 16px;
    color: white;
}

.select-cursor {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid white;
    margin-left: 9px;
}

.select-dropdown {
    position: absolute;
    margin-top: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 16px;
    -webkit-animation: visible .3s ease-in-out;
            animation: visible .3s ease-in-out;
    min-width: 200px;
}

.select-dropdown-item {
    margin-top: 16px;
    font-size: 16px;
}

.select-dropdown-item:first-child {
    margin: 0;
}

.select-dropdown-item:hover {
    color: #10ADD0;
}

@-webkit-keyframes visible {
    0% {
        top: 30px;
        opacity: 0;
    }

    100% {
        top: 100%;
        opacity: 1;
    }
}

@keyframes visible {
    0% {
        top: 30px;
        opacity: 0;
    }

    100% {
        top: 100%;
        opacity: 1;
    }
}

.mobile-menu-layout {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.mobile-menu-content {
    background: white;
    position: relative;
    width: 300px;
    float: right;
    height: 100vh;
    padding: 84px 35px;
    -webkit-animation: fromRight 0.3s ease-in-out;
            animation: fromRight 0.3s ease-in-out;
    overflow-y: scroll;
}

.mobile-menu-content__close {
    cursor: pointer;
    display: block;
    position: fixed;
    top: 33px;
    right: 30px;
    z-index: 9999;
}

.mobile-menu-content__close:hover path {
    fill: #10ADD0;
}

.header__lang.select.is-mobile,
.header__services.select.is-mobile {
    display: block;
    margin-bottom: 32px;
    margin-left: 0;
}

.header__lang-label.is-mobile,
.header__services-label.is-mobile {
    text-align: left;
}

.header__lang-label.is-mobile .select-label,
.header__services.is-mobile .select-label {
    color: black;
}

.header__lang-label.is-mobile .select-cursor,
.header__services.is-mobile .select-cursor {
    border-top-color: black;
}

.header__lang-dropdown.is-mobile,
.header__services.is-mobile .select-dropdown {
    position: static;
    box-shadow: none;
    padding: 0;
}

.header__lang-dropdown.is-mobile .select-dropdown-item,
.header__services.is-mobile .header__services-item {
    margin-top: 24px;
    font-weight: 500;
}

@-webkit-keyframes fromRight {
    0% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes fromRight {
    0% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0);
    }
}

.header {
    display: block;
    position: fixed;
    width: 100%;
    padding: 20px 10%;
    top: 0;
    left: 0;
    text-align: left;
    height: 72px;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
}

.header svg {
    height: 32px;
}

.header_is-hide {
    transform: translateY(-72px);
}

.header_is-invert {
    border-bottom: 1px solid #D6E4E7;
    background: white;
}

.header_is-invert a,
.header_is-invert span,
.header_is-invert div {
    color: black;
}

.header_is-invert .select-cursor {
    border-top-color: black;
}

.header_is-invert svg path:nth-child(1) {
    fill: black;
}

.header_is-invert svg path:nth-child(2) {
    fill: #00D0FF;
}

.header__lang.select {
    margin-left: 56px;
    min-width: auto;
}

.header__services .select-dropdown {
    min-width: 230px;
}

.header__services.select,
.header__lang-dropdown {
    min-width: auto;
}

.header__services-label,
.header__lang-label {
    font-weight: 500;
}

.header__services-item {
    text-decoration: none;
    display: block;
    color: black;
}

.header__services-item:hover {
    color: #10ADD0;
}

.header__hamburger {
    display: none;
    vertical-align: middle;
    float: right;
    cursor: pointer;
}

.header__hamburger:hover path {
    fill: #10ADD0;
}

.content-wrapper {
    overflow: auto;
}

.content-wrapper__title {
    margin-bottom: 24px;
}

.content-wrapper__img {
    margin-bottom: 16px;
}

.content-wrapper__img.left {
    margin-right: 32px;
}

.content-wrapper__img.right {
    margin-left: 32px;
}

.content-wrapper.with-image .content-wrapper__img {
    width: calc(50% - 32px);
}

.content-wrapper.with-image  .content-wrapper__content {
    vertical-align: top;
    width: 50%;
    display: inline-block;
}

.content-wrapper__text p {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

.content-wrapper__text p:last-child {
    margin-bottom: 0;
}

.content-wrapper__text {
    white-space: pre-wrap;
}

footer {
    padding: 56px 160px 0;
    background-color: #F1F6F8;
    text-align: center;
}

.footer__container {
    display: table;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-bottom: 140px;
}

.footer__link-container {
    display: table;
    margin-left: auto;
    margin-right: auto;
    float: left;
}

.footer__block svg path:nth-child(1) {
    fill: black;
}

.footer__block svg path:nth-child(2) {
    fill: #00D0FF;
}

.footer__block:first-child {
    margin-right: 106px;
}

.footer__link-container .footer__block {
    margin-right: 32px;
}

.footer__block {
    display: table-cell;
    vertical-align: top;
    position: relative;
    min-width: 160px;
    height: 100%;
    float: left;
}

.footer__block * {
    display: block;
    text-align: left;
}

.footer__block__label {
    line-height: 32px;
    margin-bottom: 16px;
    font-weight: 500;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.footer__block__rights {
    width: 160px;
    color: #BBBBBB;
    line-height: 20px;
    font-size: 12px;
    position: absolute;
    bottom: 60px;
    left: 0;
    text-align: left;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.footer__block__email {
    display: block;
    margin-top: 22px;
}

.footer__block__email-value,
.footer__block__email span {
    display: inline;
}

.footer__block__item {
    line-height: 24px;
    margin-bottom: 16px;
    color: #10ADD0;
    text-decoration: none;
}

.footer__block__item:last-child {
    margin-bottom: 0;
}

.benefit-items {
    display: inline-table;
}

.benefit-items__item-container {
    display: table-cell;
}

.benefit-items__item {
    margin-right: 34px;
}

.benefit-items__item-container:last-child .benefit-items__item {
    margin-right: 0;
}

.benefit-items__item__img {
    height: 64px;
    width: 64px;
}

.benefit-items__item__title,
.benefit-items__item__text {
    margin-top: 16px;
}

.benefit-items__item__text {
    color: #444444;
}

@media (max-width: 1230px) {
    .footer__link-container {
        margin-top: 8px;
        margin-bottom: 88px;
    }
    .footer__link-container .footer__block {
        margin-top: 24px;
    }
}

@media (max-width: 900px) {
    .header__hamburger {
        display: inline-block;
    }
}


@media (max-width: 767px), (max-width: 900px) and (orientation: landscape) {
    footer {
        padding: 35px 24px 40px;
    }

    .footer__container {
        padding-bottom: 40px;
    }

    .footer__block__rights {
        bottom: 0;
    }

    .footer__block:first-child {
        margin-right: 0;
    }

    .header {
        padding: 20px 24px;
    }

    .benefit-items__item-container {
        list-style: none;
        display: list-item;
        width: 100%;
        text-align: center;
        margin-bottom: 54px;
    }

    .benefit-items__item {
        margin-right: 0;
    }

    .benefit-items__item-container:last-child {
        margin-bottom: 0;
    }

    .benefit-items__item__title {
        font-size: 18px;
    }

    .benefit-items__item__text {
        font-size: 14px;
    }
}

.location-card {
    cursor: pointer;
    width: 256px;
    text-decoration: none;
}

.location-card__image {
    border-radius: 4px;
    background-color: #f5f5f5;
    width: 100%;
    height: 231px;
}

.location-card__image:hover {
    box-shadow: 0 5px 8px rgba(0,0,0,.15);
    transform: scale(1.03);
    transition: transform 0.1s ease-in-out;
}

.location-card__name {
    display: block;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.location-card.small .location-card__name {
    margin-top: 16px;
}

.location-card__params {
    display: block;
    margin-top: 8px;
}

.location-card__params__row {
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: left;
    font-weight: normal;
    font-size: 16px;
    color: #444444;
    overflow: visible;
    margin-top: 8px;
}

.location-card__params__row__label {
    width: 70%;
    display: inline-block;
    word-break: break-word;
}

.location-card__params__row__value {
    width: 30%;
    text-align: right;
    display: inline-block;
}

.select {
    text-align: left;
    min-width: 120px;
}

.select .dropdown.radio-select .tag-item {
    margin: 0;
    padding: 0;
    color: black;
    background: none;
    font-weight: 400;
}

.select .dropdown.radio-select .tag-item button,
.select .dropdown.radio-select .tag-item:last-child {
    display: none;
}

.select .dropdown.radio-select .node label {
    width: 100%;
}

.select .dropdown.radio-select .node input[type="radio"] {
    float: right;
}

.select .dropdown-trigger {
    display: block;
    position: relative;
    border: 2px solid #DADADA;
    border-radius: 2px;
    height: 100%;
    max-height: 100px;
    width: 100%;
    background: white;
    padding: 10px 16px;
    outline: none;
    overflow: auto;
}

.select .dropdown-trigger:hover {
    border-color: #10ADD0;
}

.select .tag-list {
    position: relative;
}

.select .toggle {
    outline: none;
}

.select .toggle:before {
    display: inline-block;
    content: url(/static/media/dropdown.46b5e256.svg);
    margin-right: 16px;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
}

.select .tag-item {
    display: inline-block;
}

.select .tag-item:last-child {
    width: 100%;
}

.select .tag-item input {
    border: none;
    background: none;
    border-radius: 0;
    padding: 0;
}

.select .tag-item:not(:last-child) {
    border-radius: 2px;
    background-color: #10ADD0;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-right: 8px;
    margin-bottom: 8px;
}

.select .tag-item button {
    background: none;
    border: none;
    color: white;
    margin-left: 4px;
    cursor: pointer;
    outline: none;
}

.select input[type="text"] {
    width: 100%;
}

.select .node {
    margin-top: 16px;
    position: relative;
}

.select .node-label,
.select .node label {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
}

.select .node[aria-expanded="true"] .toggle:before {
    transform: rotate(90deg);
    margin-left: 1px;
}

.select .node:first-child {
    margin-top: 0;
}

.select .node#custom_li {
    display: none;
}

.select .node input[type="checkbox"] {
    position: absolute;
    right: 0;
}

.select .dropdown-content {
    position: absolute;
    width: 100%;
    background-color: white;
    max-height: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 16px;
    margin-top: 8px;
    -webkit-animation: visible 0.3s ease-in-out;
            animation: visible 0.3s ease-in-out;
    z-index: 1;
    overflow: auto;
}

@-webkit-keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.hint {
    position: relative;
}

.hint-text {
    padding: 10px;
    text-align: left;
    background: black;
    color: white;
    opacity: 0.9;
    box-shadow: 04px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 8px;
    text-transform: none;
    min-width: 200px;
    z-index: 9999;
}

.form-group {
    display: block;
    position: relative;
}

.form-group__label {
    position: relative;
    margin-left: 16px;
    margin-bottom: 8px;
    height: 30px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    box-sizing: content-box;
}

.form-group__label__text {
    position: absolute;
    bottom: 4px;
    display: inline-block;
    max-width: 85%;
    word-break: break-word;
}

.form-group__value {
    display: inline-block;
    vertical-align: bottom;
}

.form-group__value > :first-child {
    width: 100%;
}

.form-group__error {
    display: inline-block;
    color: #E1414F;
    margin-top: 8px;
    overflow: hidden;
}

.form-group__hint {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
}

.location-select-container {
    position: relative;
    box-sizing: content-box;
    display: inline-block;
}

.location-select-container,
.location-select-container .form-group__value {
    width: 100%;
}

.location-select-container .location-select {
    width: calc(100% - 176px);
}

.btn.filters__submit {
    margin-left: 16px;
    vertical-align: top;
    padding: 12px 16px;
    min-width: 160px;
}

.location-select .node {
    margin-top: 0;
    margin-bottom: 16px;
}

.location-select .infinite-scroll-component {
    overflow: hidden !important;
}

.location-select .node:last-child {
    margin-bottom: 1px;
}

@media (min-width: 769px) {

    .filters__submit.is-desktop {
        display: inline-block;
    }

    .filters__submit.is-mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .location-select-container .location-select {
        width: 100%;
    }

    .filters__submit.is-desktop {
        display: none;
    }

    .filters__submit.is-mobile {
        display: block;
        width: 100%;
        margin: 0 0 48px;
    }
}

.double-input-container {
    position: relative;
    white-space: nowrap;
    border-radius: 2px;
    border: 2px solid #D6E4E7;
    height: 39px;
    width: calc(100% - 4px);
    box-sizing: content-box;
}

.double-input-container.is-error {
    border-color: #E1414F !important;
}

.double-input-container.is-active,
.double-input-container:hover,
.double-input-container:focus {
    border-color: #10ADD0;
}

.double-input__item {
    position: absolute;
}

.double-input-container input {
    position: relative;
    border-radius: 0;
    border: none;
    width: 100%;
    height: 39px;
}

.double-input__item:first-child {
    width: 50%;
    left: 0;
}

.double-input__item:last-child {
    width: calc(50% + 1px);
    right: 0;
}

.double-input__item:last-child:after {
    content: "";
    height: calc(100% - 24px);
    width: 1px;
    background: #D6E4E7;
    position: absolute;
    top: 12px;
    left: 0;
}

/* Show */

.advanced-filters-action {
    text-align: left;
    margin-bottom: 32px;
}

.advanced-filters-action__icon {
    transition: transform 0.2s ease-in-out;
    margin-right: 14px;
}

.advanced-filters-action__icon.is-mobile {
    display: none;
}

.advanced-filters-action__icon path {
    fill: white;
}

.advanced-filters-action__btn:hover .advanced-filters-action__icon path {
    fill: #1090b3 !important;
}

.advanced-filters-action__icon.is-rotate {
    transform: rotate(90deg);
}

/* Container */

.advanced-filters-container {
    overflow: visible;
    -webkit-animation: visible 0.3s ease-in-out;
            animation: visible 0.3s ease-in-out;
}

.advanced-filters-container__close {
    display: none;
    cursor: pointer;
}

.advanced-filters-container__close:hover path {
    fill: #10ADD0;
}

/* Footer */

.advanced-filters-container__footer {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 24px;
    background: white;
    z-index: 9999;
}

.advanced-filters-container__footer .btn {
    width: 50%;
}

.advanced-filters-container__footer .btn:first-child {
    margin-right: 15px;
    width: calc(50% - 15px);
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .advanced-filters-container {
        position: fixed;
        height: 100vh;
        width: 100%;
        background: white;
        top: 0;
        left: 0;
        padding: 98px 24px 0;
        z-index: 9999;
        overflow-y: scroll;
    }

    .advanced-filters-container__content {
        padding-bottom: 80px;
    }

    .advanced-filters-container__footer {
        display: block;
    }

    .advanced-filters-action__icon:not(.is-mobile) {
        display: none;
    }

    .advanced-filters-action__icon.is-mobile {
        display: inline-block;
        vertical-align: text-top;
    }

    .advanced-filters-container .filters__row-title,
    .advanced-filters-container .form-group__label__text,
    .advanced-filters-container .checkbox-filter label {
        color: black;
    }

    .advanced-filters-container .form-group__hint svg path {
        fill: black;
    }

    .advanced-filters-container__close {
        display: block;
        position: fixed;
        top: 33px;
        right: 30px;
        z-index: 9999;
    }
}

.filters {
    width: calc(200px * 4 + 16px * 3);
}

/* Row */
.filters__row {
    text-align: left;
    margin: 0 auto 40px;
}

.filters__row-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: white;
    text-align: left;
    margin-bottom: 16px;
}

.filters__row.is-desktop:nth-child(5) {
    margin-bottom: 16px;
}

.filters__row .form-group__value {
    width: 100%;
}

/* Filter */
.filters__filter {
    display: inline-block;
    margin-right: 16px;
    vertical-align: text-top;
    width: calc(100% / 4 - 12px);
}

.filters__filter input {
    border-width: 2px;
}

.filters__row.is-many-filters > .filters__filter:nth-child(4n),
.filters__filter:last-child {
    margin-right: 0;
}

.filters__row.is-many-filters > .filters__filter {
    margin-bottom: 16px;
    vertical-align: text-top;
}

/* Labels in filter */
.filters__filter .toggle {
    display: none;
}
.filters__filter .select .node-label,
.filters__filter .select .node label {
    width: 80%;
}

.filters__filter input[type="radio"] {
    margin-top: 3px;
}

/* Certain filter: select-size */

.filters__filter .select.select-size .dropdown {
    min-height: 43px;
}

.filters__filter .select.select-size .dropdown-trigger {
    padding: 8px 16px;
    min-height: inherit;
}

.filters__filter .select.select-size .tag-item {
    vertical-align: middle;
    margin-top: 8px;
    margin-bottom: 0;
}

.filters__filter .select.select-size .tag-list .tag-item:first-child {
    display: inline-block;
    margin-top: 0;
}

.filters__filter .select.select-size .tag-item:last-child {
    display: none;
}

/* Certain filter: checkbox */

.checkboxes-container {
    display: table;
}
.checkboxes-container > .checkbox-filter {
    vertical-align: top;
    min-width: 200px;
    margin-right: 16px;
    margin-bottom: 16px;
}

.filters__filter.checkbox-filter {
    width: auto;
    margin-right: 16px;
}

.checkbox-filter {
    display: inline-flex;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 16px;
}

.checkbox-filter input[type="checkbox"] {
    margin-top: 3px;
}

.checkbox-filter label {
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: table-cell;
    padding-left: 11px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

/* Certain filter: double-input */

.filters__filter .double-input-container {
    width: calc(100% - 4px);
}

.warning__unsupported-filters {
    margin-top: 16px;
    font-size: 14px;
    line-height: 24px;
}

.warning__unsupported-filters__icon {
    height: 20px;
    width: 20px;
    vertical-align: top;
    margin-top: 3px;
}

.warning__unsupported-filters__icon path {
    fill: #e1414f;
}

.warning__unsupported-filters__text {
    margin-left: 10px;
    display: inline-block;
    width: calc(100% - 20px - 10px);
}

@-webkit-keyframes visible {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes visible {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 769px) {
    .filters__row.is-desktop,
    .filters__row-title.is-desktop {
        display: block;
    }

    .filters__filter.is-desktop {
        display: inline-block;
    }

    .filters__filter.is-mobile:not(.is-desktop),
    .filters__row-title.is-mobile:not(.is-desktop),
    .filters__row.is-mobile:not(.is-desktop) {
        display: none;
    }

    .filters__row:nth-child(6) {
        margin-bottom: 0;
    }
}

@media (max-width: 1024px) {
    .filters {
        max-width: 90vw;
    }
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .filters__row:first-child {
        margin-bottom: 24px;
    }

    .filters__row {
        margin-bottom: 8px;
    }

    .filters__row:nth-child(3),
    .filters__row:nth-child(6) {
        margin-bottom: 0;
    }

    .filters__filter:not(.checkbox-filter):nth-child(2n) {
        margin-right: 0;
    }

    .filters__filter {
        width: calc(100% / 2 - 8px) !important;
        margin-bottom: 16px;
    }

    .filters__filter.is-desktop,
    .filters__row-title.is-desktop,
    .filters__row.is-desktop {
        display: none;
    }

    .filters__filter.is-mobile {
        display: inline-block;
    }

    .filters__row.is-mobile,
    .filters__row-title.is-mobile {
        display: block;
    }
}

@media (max-width: 768px) and (orientation: portrait) {
    .filters__row:nth-child(3) {
        margin-bottom: 24px;
    }
}

.content {
    width: 100%;
    text-align: center;
    padding: 76px 160px;
}

.content__cards-container {
    display: inline-block;
    margin-top: 64px;
    text-align: left;
    margin-bottom: 96px;
    width: 100%;
}

.content__cards-container:nth-child(5) {
    margin-top: 0;
}

.content__cards-container > .location-card {
    display: inline-block;
    margin-right: 32px;
    margin-bottom: 32px;
    width: calc(100% / 4 - 32px);
    vertical-align: text-top;
}

.content__cards-container > .location-card:last-child {
    margin-right: 0;
    margin-bottom: 0;
}

.content__cards-container > .service-card {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    width: calc(100% / 3 - 30px);
}

.content__cards-container > .service-card:last-child {
    margin-right: 0;
    margin-bottom: 0;
}

@media (max-width: 1270px) {
    .content {
        padding-left: 100px;
        padding-right: 100px;
    }

    .content__cards-container > .location-card {
        width: calc(100% / 2 - 16px);
    }

    .content__cards-container > .location-card:nth-child(2n) {
        margin-right: 0;
    }
}

@media (max-width: 900px) {
    .content .title {
        font-size: 22px;
    }

    .content__cards-container > .service-card:last-child,
    .content__cards-container > .service-card {
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: auto;
        max-width: 354px;
    }
}

@media (max-width: 768px) {
    .content {
        padding: 56px 24px;
    }

    .content__cards-container {
        text-align: center;
        margin-bottom: 56px;
    }

    .content__cards-container > .location-card {
        width: 256px;
    }
}

@media (max-width: 600px) {
    .content__cards-container > .location-card:nth-child(2n),
    .content__cards-container > .location-card {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .get-in-touch-form__input,
    .get-in-touch-form__button {
        min-width: auto;
        display: block;
        width: 100%;
        margin-top: 24px;
    }
}

.layout {
    margin-top: 71px;
    padding-top: 47px;
    padding-left: 15%;
    padding-right: 15%;
}

@media (max-width: 1280px) {
    .layout {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (max-width: 900px) {
    .layout {
        padding: 16px 24px;
    }
}

.question-container {

}

.question-container__title {
    margin-bottom: 32px;
    line-height: 40px;
}

.question-item {
    padding-top: 24px;
    padding-bottom: 24px;
    display: block;
    border-top: 1px solid #D6E4E7;
    cursor: pointer;
}

.question-item:last-child {
    border-bottom: 1px solid #D6E4E7;
}

.question-item__title {
    line-height: 24px;
    font-size: 20px;
    width: 80%;
}

.question-item__icon-container {
    display: inline-block;
    width: 20%;
    text-align: right;
}

.question-item__title,
.question-item__icon {
    display: inline-block;
    vertical-align: middle;
}

.question-item__icon {
    transition: transform 0.1s ease-in-out;
}

.question-item__icon.is-rotate {
    transform: rotate(45deg);
}

.question-item__text {
    margin-top: 16px;
    -webkit-animation: visible 0.3s ease-in-out;
            animation: visible 0.3s ease-in-out;
    color: #444444;
}

@-webkit-keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .question-container__title {
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 16px;
    }

    .question-item__title {
        font-size: 18px;
        line-height: 28px;
    }
}

.feedback-form-container {
    max-width: 540px;
}

.feedback-form__title {
    margin-bottom: 32px;
}

.feedback-form {
    width: 100%;
}

.feedback-form__footer {
    display: inline-block;
}

.feedback-form__footer .get-in-touch__status {
    padding-left: 0;
}

.feedback-form__footer .get-in-touch__status.is-error {
    text-align: left;
}

.feedback-form__item {
    margin-bottom: 16px;
}

.feedback-form__item .form-group__value {
    width: 100%;
}

.feedback-form__item textarea {
    min-height: 150px;
    resize: none;
}

.feedback-form__btn {
    width: 250px;
    height: 48px;
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .feedback-form__title {
        font-size: 26px;
    }

    .feedback-form__btn {
        width: 100%;
    }
}

.btn {
    background: #10ADD0;
    border-radius: 2px;
    padding: 14px 16px;
    border: none;
    outline: none;
    text-transform: uppercase;
    color: white;
    min-width: 120px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer !important;
}

.btn:hover,
.btn:hover .loader:after {
    background: #1090b3;
}

.btn.link {
    background: none;
    padding: 0;
    font-weight: 600;
    width: auto;
    min-width: auto;
}

.btn.link:hover {
    color: #1090b3;
}

.btn.cancel {
    background-color: #BBBBBB;
}

.btn.cancel:hover {
    background-color: #adadad;
}

.loader {
    font-size: 10px;
    margin: auto;
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: #10ADD0;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.imprint-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.partners {}

.partners .content-wrapper,
.partners .benefit-items,
.partners .question-container,
.partners .feedback-form {
    margin-bottom: 96px;
}
.first-content {
    max-width: 733px;
    margin-bottom: 24px;
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .partners {
        padding: 24px;
    }

    .content-wrapper__title {
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 16px;
    }

    .content-wrapper__text p {
        font-size: 14px;
        line-height: 20px;
    }

    .partners .benefit-items,
    .partners .question-container,
    .partners .feedback-form,
    .partners .content-wrapper {
        margin-bottom: 56px;
    }

    .content-wrapper.with-image .content-wrapper__img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
    }

    .content-wrapper.with-image .content-wrapper__content {
        width: 100%;
    }
}

.filters-column {
    width: 200px;
}

.filters-column .filters__row  {
    margin-bottom: 32px;
}

.filters-column .filters__filter {
    width: 100%;
    margin-bottom: 16px;
}

.filters-column .filters__row .filters__filter:last-child {
    margin-bottom: 0;
}

.locations-page {
    min-height: 100vh;
    padding-top: 24px;
}

.ant-tooltip {
    display: none;
}

.locations-page__mobile-filters {
    display: none;
}

.locations-page .filters__row-title {
    color: black;
    margin-bottom: 24px;
}

.locations-page .checkbox-filter label {
    color: black;
}

.locations-page__content {
    margin-top: 32px;
}

.locations-page__content .filters-column,
.locations-page__content .locations-page__results {
    display: inline-block;
}

.locations-page .filters .advanced-filters-action__btn {
    color: black;
}

.locations-page__content .form-group__hint svg path,
.locations-page .filters .advanced-filters-action__icon * {
    fill: black;
}

.locations-page__no-data,
.locations-page__content .locations-page__results {
    vertical-align: top;
    width: calc(100% - 224px);
    margin-left: 24px;
    margin-bottom: 32px;
}

.locations-page__results table {
    width: 100%;
    border-spacing: 0;
}

.locations-page__results table  .ant-empty {
    display: none;
}

.locations-page__results thead th {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #828282;
    text-align: left;
    padding-left: 16px;
    cursor: pointer;
}

/*.locations-page__results thead th:nth-last-child(2) .ant-table-column-sorters,*/
/*.locations-page__results thead th:nth-last-child(3) .ant-table-column-sorters {*/
/*    white-space: normal;*/
/*}*/

.locations-page__results tbody:before {
    content: '';
    display:block;
    height: 8px;
}

.locations-page__results tbody tr td {
    padding: 16px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    vertical-align: top;
}

.locations-page__results tbody tr td:first-child {
    width: 200px;
}

.locations-page__results.is-visible-state tbody tr td:nth-child(2) {
    display: none;
}

.ant-table-column-sorter {
    display: none;
}

.locations-page__results tbody tr td .cell__label {
    display: none;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
    width: 80%;
}

.locations-page__results tbody tr td .cell__value {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.locations-page__results tbody tr:nth-child(2n) {
    background-color: #F1F6F8;
}

.locations-page__results tbody tr:hover {
    background-color: #D6E4E7;
    cursor: pointer;
}

.locations-page__results__locations-container {
    position: relative;
}

.locations-page__results__location {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
}

.locations-page__results__university {
    vertical-align: text-top;
    height: 14px;
}

.locations-page__results__university-hint-container {
    height: 16px;
    margin-top: 4px;
}

.locations-page__results__university-hint {
    width: 100px;
    text-align: center;
    min-width: auto;
    font-size: 12px;
}

.locations-page__results__state {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #444444;
}

.locations-page__results .ant-table-pagination {
    list-style: none;
    margin-top: 16px;
    text-align: right;
}
.locations-page__results .ant-table-pagination li.ant-pagination-prev,
.locations-page__results .ant-table-pagination li.ant-pagination-item-active,
.locations-page__results .ant-table-pagination li.ant-pagination-next {
    display: inline-block;
}

.locations-page__results .ant-table-pagination li.ant-pagination-item-active {
    color: #10ADD0;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 600;
}

.locations-page__results .ant-table-pagination li button {
    background: none;
    border-radius: 2px;
    padding: 4px;
    outline: none;
    text-transform: uppercase;
    color: #10ADD0;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #10ADD0;
}

.locations-page__results .ant-table-pagination li button:hover {
    background-color: #10ADD0;
    color: white;
}

.locations-page__results .ant-table-pagination li button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.locations-page__results .ant-table-pagination li {
    display: none;
}

.locations-page__no-data {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.ant-table-column-sorters {
    white-space: nowrap;
}

.table-header {
    display: inline-block;
}

.table-header span {
    vertical-align: text-bottom;
}

.table-header.is-ascend-sort .table-header__icon,
.table-header.is-descend-sort .table-header__icon {
    opacity: 1;
}

.table-header.is-ascend-sort span,
.table-header.is-descend-sort span {
    color: #10ADD0;
}

.table-header.is-descend-sort .table-header__icon {
    transform: rotate(180deg);
}

.table-header__icon {
    display: inline-block;
    opacity: 0;
    margin-left: 8px;
}

.locations-page__results__filter:hover path,
.table-header__icon path {
    fill: #10ADD0;
}

.table-header__filter {
    position: relative;
}

.locations-page__results__filter {
    cursor: pointer;
}

.locations-page__results__list-columns {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 24px;
    list-style: none;
    width: 250px;
    -webkit-animation: visible 0.3s ease-in-out;
            animation: visible 0.3s ease-in-out;
    z-index: 1;
}

@-webkit-keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes visible {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.locations-page__results__list-columns__item {
    display: list-item;
    margin-bottom: 16px;
    line-height: 24px;
    width: 100%;
}

.locations-page__results__list-columns__item:last-child {
    margin-bottom: 0;
}

.locations-page__results__list-columns__item input {
    float: right;
}

.locations-page__content .advanced-filters-action {
    display: none;
}

@media (min-width: 900px) {
    .locations-page__content .advanced-filters-container {
        display: block;
    }
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
    .locations-page__content .advanced-filters-action {
        display: inline-block;
    }

    .locations-page__mobile-filters {
        display: block;
    }

    .locations-page__content .locations-page__content__advanced-filters {
        display: none;
    }

    .locations-page__content {
        margin-top: 24px;
    }

    .locations-page__content .filters-column {
        width: 100%;
    }

    .filters-column .filters__row:nth-child(8) {
        margin-bottom: 0;
    }

    .locations-page__content .advanced-filters-action__btn {
        color: black;
    }

    .locations-page__content .advanced-filters-action__icon path {
        fill: black;
    }

    .locations-page__content .locations-page__content__advanced-filters.is-show {
        display: block;
    }

    .locations-page__no-data,
    .locations-page__content .locations-page__results {
        width: calc(100% + 48px);
        margin-left: -24px;
    }

    .locations-page__results tbody,
    .locations-page__results thead {
        display: block;
    }

    .locations-page__results thead tr {
        display: inline-block;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }

    .locations-page__results thead th {
        margin-right: 32px;
        padding: 0;
        display: inline-block;
    }

    .locations-page__results tbody tr {
        display: block;
        width: 100%;
        padding: 12px 24px;
    }

    .locations-page__results tbody tr td {
        display: block;
        padding: 4px;
    }

    .locations-page__results thead th:nth-child(2),
    .locations-page__results thead th:nth-last-of-type(1),
    .locations-page__results tbody tr td:nth-last-of-type(1) {
        display: none;
    }

    .locations-page__results tbody tr td .cell__label {
        display: inline-block;
    }

    .locations-page__results tbody tr td .cell__value {
        width: 20%;
        text-align: right;
    }

    .locations-page__results .ant-table-pagination {
        margin-right: 24px;
    }
}

body, button, textarea {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea,
input {
  padding: 12px 16px;
  border: 1px solid #D6E4E7;
  border-radius: 2px;
  outline: none;
  caret-color: #10ADD0;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: #BBBBBB;
}

textarea:-ms-input-placeholder, input:-ms-input-placeholder {
  color: #BBBBBB;
}

textarea::-ms-input-placeholder, input::-ms-input-placeholder {
  color: #BBBBBB;
}

textarea::placeholder,
input::placeholder {
  color: #BBBBBB;
}

textarea:hover,
textarea:focus,
input:hover,
input:focus {
  border-color: #10ADD0;
}

textarea.is-error,
input.is-error {
  border-color: #E1414F;
}

@media (max-width: 768px), (max-width: 900px) and (orientation: landscape) {
  input {
    max-height: 43px;
    font-size: 16px;
  }
}

input[type="checkbox"] {
  position: relative;
  height: 18px;
  width: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: text-top;
  border: none;
  padding: 0;
  z-index: 0;
}

input[type="checkbox"]:hover:before {
  border-color: #10ADD0;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #DADADA;
  border-radius: 2px;
  box-sizing: border-box;
}

input[type="checkbox"]:checked {
  background-color: #10ADD0;
}

input[type="checkbox"]:disabled {
  background-color: #DADADA;
}

input[type="checkbox"]:indeterminate:before,
input[type="checkbox"]:checked:before {
  height: 6px;
  width: 11px;
  transform: rotate(-50deg);
  border-top-style: none;
  border-right-style: none;
  border-color: white;
  margin-left: 3.5px;
  margin-top: 5px;
  border-radius: 0;
}

input[type="checkbox"]:indeterminate {
  border: 2px solid #10ADD0;
}

input[type="checkbox"]:indeterminate:hover {
  background-color: #10ADD0;
}

input[type="checkbox"]:indeterminate:hover:before {
  border-color: white;
}

input[type="checkbox"]:indeterminate:before {
  width: 10px;
  border-color: #10ADD0;
  margin-top: 3px;
  margin-left: 2px;
}

[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 13px;
  width: 13px;
  padding: 0;
  border: none;
}

[type="radio"]:checked,
[type="radio"]:not(:checked)
{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
[type="radio"]:checked:before,
[type="radio"]:not(:checked):before {
  content: '';
  position: absolute;
  /* left: -1px; */
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  box-sizing: border-box;
}

[type="radio"]:hover:before,
[type="radio"]:checked:before {
  border-color: #10ADD0;
}

[type="radio"]:checked:after,
[type="radio"]:not(:checked):after {
  content: '';
  width: 7px;
  height: 7px;
  background: #10ADD0;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked):after {
  opacity: 0;
  transform: scale(0);
}
[type="radio"]:checked:after {
  opacity: 1;
  transform: scale(1);
}


